<script setup>
 // Props
 const props = defineProps({
  page: {
   type: Object,
   required: true,
  },
 });

 const { page } = toRefs(props);
</script>

<!-- These "templates" need to return a single root node -->
<!-- Otherwise Vue get's mad -->
<template>
 <div>
  <main id="main" key="page" class="mess-page" tabindex="-1">
   <TemplatesToursHeader :page="page" />
   <TemplatesToursDetails :tour="page" />
   <MessRenderContent :page="page" />
  </main>
 </div>
</template>
